import {ApiService} from '@/services/ApiService'
import http from '@/http-common'

const URL = '/api/location'

class CustomerService extends ApiService {
  constructor(){
    super()
  }
  getUrl(){
    return URL
  }
}

export default new CustomerService()